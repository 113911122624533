import LogoDomyDnes from "../images/reference/domy-dnes/logo-domydnes.png"
import LogoFenstar from "../images/reference/fenstar/logo.svg"
import LogoPlaneta from "../images/reference/planetaher/logo-planeta.png"
import LogoDoktorKladivo from "../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import LogoRoyalCanin from "../images/reference/royal-canin/logo-rc.png"
import LogoSZ from "../images/reference/snowboard-zezula/logo-sz.png"

export const VYVOJ_REFERENCES = [
  {
    image: LogoRoyalCanin,
    alt: "Royal Canin",
    link: "/vyvoj/reference/royal-canin",
  },
  {
    image: LogoDoktorKladivo,
    alt: "doktorkladivo.cz",
    link: "/vyvoj/reference/doktor-kladivo",
  },
  {
    image: LogoSZ,
    alt: "snowboardzezula.cz",
    link: "/vyvoj/reference/snowboard-zezula",
  },
  {
    image: LogoFenstar,
    alt: "fenstar.cz",
    link: "/vyvoj/reference/fenstar",
  },
  {
    image: LogoPlaneta,
    alt: "planetaher.cz",
    link: "/vyvoj/reference/planetaher",
  },
  {
    image: LogoDomyDnes,
    alt: "domy-dnes.cz",
    link: "/vyvoj/reference/domy-dnes",
  },
]
