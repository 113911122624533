import React from "react"
import { Helmet } from "react-helmet"
import {
  SectionWrapper,
  SectionWrapperBackgroundStyle,
  H2,
  ReferencesWithoutBorder,
  FancyReferenceBlock,
  Banner,
  Link,
  BannerStyle,
  already,
} from "@igloonet-web/shared-ui"
import FloeIntroductionBlock from "../components/vyvoj/floe-introduction-block"

import { ContactForm, Layout } from "../components"
import { VYVOJ_REFERENCES } from "../model/vyvoj-references"

import Dog from "../../src/images/vyvoj/dog-tablet.svg"
import Smith from "../../src/images/vyvoj/penguin_smith.svg"
import Snowboarder from "../../src/images/vyvoj/penguin_snowboarder.svg"

import LogoDoktorKladivo from "../images/reference/doktor-kladivo/logo-doktorkladivo.png"
import LogoRoyalCanin from "../images/reference/royal-canin/logo-rc.png"
import LogoSZ from "../images/reference/snowboard-zezula/logo-sz.png"

const Vyvoj = (): React.ReactNode => {
  return (
    <Layout>
      <Helmet>
        <title>Vývoj webů a webových aplikací | igloonet</title>
        <meta
          name="description"
          content="Věnujeme se tvorbě webových stránek a aplikací na míru.
            Dokážeme optimalizovat váš web směrem k větší efektivitě. Nezalekneme
            se ani rozsáhlejších webových projektů."
        />
      </Helmet>

      <Banner
        style={BannerStyle.Vyvoj}
        orangeHeading="Pomůžeme vám"
        blackHeading="s&nbsp;příštím projektem"
        text="Máme zkušenosti s&nbsp;malými i&nbsp;velkými e&#8209;shopy, aplikacemi i&nbsp;portály pro náročné klienty. Postaráme se i&nbsp;o&nbsp;hosting, SEO, PPC a&nbsp;strategie pro sociální sítě. Rádi se ale pustíme i&nbsp;do něčeho úplně nového.
        Budeme spolehlivým parťákem u&nbsp;projektů, na které si ostatní netroufnou. Pokud váš projekt zatím nemá pevné obrysy a&nbsp;mantinely, pomůžeme vám s&nbsp;jejich definováním."
      />

      <FloeIntroductionBlock />

      <FancyReferenceBlock
        image={Dog}
        alt=""
        logoImage={LogoRoyalCanin}
        firstHeadingPart="B2B aplikace "
        secondaryHeadingPart="ušetřila třetinu času"
        firstParagraph={`V\u00A0Royal Canin probíhala všechna produktová komunikace s\u00A0B2B partnery 
        „ručně“. Díky naší automatizaci vidí všechny nové produkty téměř bez práce a\u00A0v\u00A0reálném čase.`}
        secondaryParagraph={`Zvýšili jsme tím počet vyřízených objednávek o\u00A010\u00A0% a\u00A0zákaznické podpoře 
        ušetřili až 35\u00A0% času. Z\u00A0naší aplikace Portail se stal nejlépe hodnocený projekt ve společnosti 
        Royal Canin v\u00A0zemích střední a\u00A0severní Evropy za poslední dva roky.`}
        buttonLink="vyvoj/reference/royal-canin"
        buttonText="Celá reference"
      />

      <FancyReferenceBlock
        image={Smith}
        alt=""
        logoImage={LogoDoktorKladivo}
        firstHeadingPart="E‑shop pro více než "
        secondaryHeadingPart="40 000 produktů"
        firstParagraph={`400 kategorií, 36 typových podstránek a\u00A0víc než 12 různých výrobců -\u00A0takový 
        e‑shop jsme vytvořili pro Doktora Kladivo.`}
        secondaryParagraph={`A\u00A0nezůstalo jen u\u00A0toho, naše spolupráce pokračuje. Společně jsme řešili 
        napojení na Pohodu a\u00A0mailingový nástroj Mailkit, víc než 12 různých automatických importů od 
        dodavatelů a\u00A0mnoho dalšího.`}
        buttonLink="vyvoj/reference/doktor-kladivo"
        buttonText="Celá reference"
        right
      />

      <FancyReferenceBlock
        image={Snowboarder}
        alt=""
        logoImage={LogoSZ}
        firstHeadingPart="Kompletní vývoj, "
        secondaryHeadingPart="marketing i správa"
        firstParagraph={`Se Snowboard Zezula spolupracujeme už ${already(
          2008
        )}. Začali jsme jim pomáhat se 
        správou sítě a\u00A0údržbou e‑shopu, na základě dobrých zkušeností nám svěřili celý vývoj. 
        Vytvořili jsme pro ně jak nový e‑shop, tak B2B platformu.`}
        secondaryParagraph={`Od roku 2010 máme na starosti i\u00A0PPC kampaně a\u00A0dnes pro klienta řešíme 
        veškerý online marketing.`}
        buttonLink="vyvoj/reference/snowboard-zezula"
        buttonText="Celá reference"
      />

      <SectionWrapper backgroundStyle={SectionWrapperBackgroundStyle.PrimaryBG}>
        <H2 className="pb-4">Další reference</H2>
        <ReferencesWithoutBorder anotherReferences={VYVOJ_REFERENCES} />
        <div className="d-flex justify-content-center mt-3">
          <Link to="/vyvoj/reference">Zobrazit více</Link>
        </div>
      </SectionWrapper>

      <div id="formular">
        <ContactForm contact="sobol" />
      </div>
    </Layout>
  )
}

export default Vyvoj
