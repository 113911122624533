import React from "react"
import styled from "styled-components"
import {
  H2,
  BottomFloeWrapper,
  InnerFloeContainer,
} from "@igloonet-web/shared-ui"

import PhpIcon from "../../../src/icons/php-icon.svg"
import LandscapeIcon from "../../../src/icons/landscape-icon.svg"
import LockIcon from "../../../src/icons/lock-icon.svg"
import SettingsIcon from "../../../src/icons/settings-icon.svg"

const InnerWrapper = styled.div`
  position: relative;
  width: 75%;
  margin: 0 auto;
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    &:after {
      content: "";
      background-color: ${(props) => props.theme.color.beige200};
      position: absolute;
      height: 75%;
      width: 3px;
      top: 39%;
      left: 50%;
      transform: translate(-50%, -40%);
    }
  }
`

const BlockWrapper = styled.div`
  height: 100%;
  position: relative;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    width: 45%;
    .dot-left:after,
    .dot-right:after {
      content: "";
      background-color: ${(props) => props.theme.color.beige200};
      position: absolute;
      height: 15px;
      width: 15px;
      top: 36%;
    }

    .dot-left:after {
      left: -11%;
      transform: translate(-50%, 0);
    }

    .dot-right:after {
      right: -11%;
      transform: translate(50%, 0);
    }
  }
`

const ContentWrapper = styled.div`
  display: block;
  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    display: flex;
  }

  p {
    line-height: 2rem;
  }
`

const FloeIntroductionBlock: React.FC = () => {
  return (
    <BottomFloeWrapper>
      <InnerFloeContainer>
        <H2 className="py-4">Proč chtít řešení od nás</H2>
        <InnerWrapper>
          <BlockWrapper className="text-center text-lg-right pb-4 pb-lg-0">
            <ContentWrapper className="flex-row-reverse">
              <div className="pb-3 dot-right">
                <PhpIcon />
              </div>
              <div>
                <h5 className="pb-2">Vaše řešení, váš kód</h5>
                <p>
                  Komplexně. Od výzkumu přes kampaně až po analytiku. Fugnujeme
                  po celé republice a&nbsp;posuneme vás o&nbsp;velký kus
                  dopředu.
                </p>
              </div>
            </ContentWrapper>
          </BlockWrapper>
          <BlockWrapper className="text-center text-lg-left pb-4 pb-lg-0 ml-auto">
            <ContentWrapper>
              <div className="pb-3 dot-left">
                <LandscapeIcon />
              </div>
              <div>
                <h5 className="pb-2">Žádné pocity, ale tvrdá data</h5>
                <p>
                  Uživatelský výzkum bereme vážně. Přesvědčíme vás, že bez
                  důkladného pochopení vašich zákazníků a&nbsp;jejich potřeb,
                  nemá cenu s&nbsp;projektem začínat. Než se pustíme do vývoje,
                  analyzujeme konkurenci, tvoříme persony a&nbsp;testujeme
                  wireframy.
                </p>
              </div>
            </ContentWrapper>
          </BlockWrapper>
          <BlockWrapper className="text-center text-lg-right pb-4 pb-lg-0">
            <ContentWrapper className=" flex-row-reverse">
              <div className="pb-3 dot-right">
                <LockIcon />
              </div>
              <div>
                <h5 className="pb-2">Práce pod kontrolou</h5>
                <p>
                  Během celé spolupráce budete mít přístup do našeho
                  projektového systému Redmine. V reálném čase můžete sledovat,
                  na čem se zrovna pracuje.
                </p>
              </div>
            </ContentWrapper>
          </BlockWrapper>
          <BlockWrapper className="text-center text-lg-left pb-4 pb-lg-0  ml-auto">
            <ContentWrapper>
              <div className="pb-3 dot-left">
                <SettingsIcon />
              </div>
              <div>
                <h5 className="pb-2">Vývoj, hosting a&nbsp;marketing</h5>
                <p>
                  V igloonetu nemáme jen vývojáře. Vašemu projektu rádi
                  zajistíme bezpečný hosting a&nbsp;navrhneme marketingovou
                  strategii na míru. PPC, SEO nebo mailing pro nás nejsou
                  problém.
                </p>
              </div>
            </ContentWrapper>
          </BlockWrapper>
        </InnerWrapper>
      </InnerFloeContainer>
    </BottomFloeWrapper>
  )
}

export default FloeIntroductionBlock
